<template>
  <NuxtLayout name="layout">
    <ul
      class="continen-container flex justify-center items-center"
      v-if="!!partList.length">
      <li
        class="flex items-center justify-center"
        :class="activeContinent === item.id ? 'active' : ''"
        v-for="item in partList"
        :key="item.part_code"
        @click="selectContinent(item)">
        <img :src="'/img/common' + item.logo" alt="" v-if="!!item.logo">
        <p class="base">{{ item.part_name }}</p>
      </li>
    </ul>
    <div
      class="wrapper"
      v-loading="loading">
      <div v-if="!!countryList.length">
        <ul class="country-list">
          <li
            v-for="item in countryList"
            :key="item.id">
            <NuxtLink :to="'/country/' + item.id">
              <div class="national-flag">
                <img :src="item.country_img_url" alt="">
              </div>
              <p>{{ item.country_name }}</p>
            </NuxtLink>
          </li>
        </ul>
      </div>
      <el-empty
        v-if="!loading && countryList.length < 1">
        <template #description></template>
      </el-empty>
    </div>
  </NuxtLayout>
</template>

<script setup>
const loading = ref(true)
const router = useRouter()
const activeContinent = ref('')
const partLogo = {
  yazhou: '/ic_asia.svg',
  nanmeizhou: '/ic_south_america.svg',
  feizhou: '/ic_africa.svg',
  ouzhou: '/ic_europe.svg',
  beimeizhou: '/ic_north_america.svg',
  nanjizhou: '',
  dayangzhou: '/ic_oceania.svg'
}
const partList = ref([])
const countryList = ref([])

// 几大洲列表
async function fetchPartList() {
  const data = await $http('/api/home/visa/part/list', {}, 'get')
  if (data.code === 200) {
    partList.value = (Array.isArray(data.data) ? data.data : []).map(item => {
      const hasIcon = Object.prototype.hasOwnProperty.call(partLogo, item.part_code)
      const logo = hasIcon ? partLogo[item.part_code] : ''

      return {
        ...item,
        logo
      }
    })

    // 默认取第一项
    if (partList.value.length > 0) {
      const [{ id }] = partList.value
      fetchCountryList(id)
      activeContinent.value = id
    }
  }
}

// 国家列表
async function fetchCountryList(part_id) {
  const data = await $http('/api/home/visa/country/list', { part_id }, 'get')
  countryList.value = []
  if (data.code === 200) {
    countryList.value = Array.isArray(data.data) ? data.data : []
  }
  loading.value = false
}

function selectContinent(item) {
  if (activeContinent.value === item.id) {
    return
  }
  loading.value = true
  fetchCountryList(item.id)
  activeContinent.value = item.id
}

onMounted(async () => {
  fetchPartList()
})
</script>

<style lang="scss" scoped>
.continen-container {
  height: 72px;
  margin-bottom: 32px;
  background: #FFFFFF;
  box-shadow: -1px 1px 10px 0 rgba(0, 0, 0, 0.2);

  li {
    min-width: 102px;
    padding: 0 12px;
    margin-left: 16px;
    color: #8F8F8F;
    cursor: pointer;
    overflow: hidden;

    &:nth-of-type(1) {
      margin-left: 0;
    }

    &:hover,
    &.active {
      height: 32px;
      color: #596387;
      background: #F6F6F6;
      border-radius: 17px;

      img {
        filter: drop-shadow(#90949b 100px 0);
        transform: translateX(-100px);
      }
    }
  }

  img {
    width: 33px;
    height: 20px;
    margin-right: 4px;
    filter: drop-shadow(#e9e9e9 100px 0);
    transform: translateX(-100px);
  }
}

.wrapper {
  max-width: 744px;
  margin: 0 auto;
}

.country-list {
  display: flex;
  flex-flow: row wrap;
  padding-bottom: 96px;

  li {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 120px;
    min-height: 114px;
    background: #FEFEFE;
    border-radius: 6px;
    border: 1px solid #F5F5F5;
    padding: 8px 0;
    margin-left: 36px;
    margin-bottom: 14px;
    overflow: hidden;
    cursor: pointer;

    &:hover,
    .active {
      box-shadow: -1px 1px 10px 0 rgba(0, 0, 0, 0.2);
    }

    &:nth-of-type(5n + 1) {
      margin-left: 0;
    }
  }

  .national-flag {
    width: 101px;
    height: 68px;
    overflow: hidden;
  }

  img {
    display: block;
    width: 100%;
    height: 100%;
  }

  p {
    margin-top: 12px;
    font-size: 12px;
    text-align: center;
  }
}

:deep(.el-empty) {
  margin: 0 auto;
}
</style>
